import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TextLink from "../../../../../components/textlink"
import Text from "../../../../../components/text"
import Image from "../../../../../components/image"
import Note from "../../../../../components/note"
import Constrain from "../../../../../components/constrain"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })
  const data = useStaticQuery(graphql`
    query KurseZahlenspieleHaelfteQuery {
      nachtgesaenge07: file(
        relativePath: { eq: "kurse/nachtgesaenge-07.png" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/gedicht-kritik" />
      }
    >
      <Seo title="Versteckten Zahlenspielen nachgehen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Versteckten Zahlenspielen nachgehen
            </Heading>
          </Stack>
          <Paragraph>
            ›Hälfte des Lebens‹ ist das siebte Gedicht in der Reihe und das
            erste, das keiner festen Strophenform folgt. Es ist freirhythmisch
            gestaltet. Damit markiert es den Übergang zwischen den
            vorausgegangen Gedichten, die sich an antiken Mustern orientieren,
            und den darauffolgenden Gedichten, die sich davon gelöst haben, was
            zu Hölderlins Zeit nicht unüblich war.
          </Paragraph>
          <Constrain>
            <Image
              alt=""
              image={data.nachtgesaenge07.childImageSharp.gatsbyImageData}
            />
          </Constrain>
          <Note title="Übrigens">
            Auch hier hat Hölderlin wieder ein Zahlenspiel versteckt: Das
            Gedicht besteht aus 2 x 7 Versen und steht an 7. Stelle des Zyklus.
            ›Der Winkel von Hardt‹, das letzte Gedicht der Reihe, besteht aus 9
            Versen und steht an 9. Stelle. Die drei freirhythmischen Gedichte
            enden darüber hinaus allesamt mit einem Adonius. Eine Hommage an die
            Dichterin Sappho.
          </Note>

          <Heading level={5}>Mehr zu den Zahlen in ›Hälfte des Lebens‹</Heading>
          <Stack space={3}>
            <Text size={3}>
              <Heading as="span">Wolfram Groddeck</Heading>:{" "}
              <TextLink href="https://www.transcript-verlag.de/978-3-89942-606-9/weiterlesen/">
                Zahl, Maß und Metrik in Hölderlins Gedicht ›Hälfte des Lebens‹
              </TextLink>
            </Text>
            <Text color="whisper">
              In: Weiterlesen. Literatur und Wissen, hrsg. von Ulrike Bergermann
              und Elisabeth Strowick, Bielefeld 2007
            </Text>
          </Stack>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
